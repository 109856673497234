import React, { useRef } from "react";
import { Container, Form } from "react-bootstrap";
import cube from "src/assets/images/contact/contactUSCube.png";
import cone from "src/assets/images/contact/contactUSCone.png";
import "./contactform.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";

const validationSchema = Yup.object().shape({
  fullName: Yup.string().required("Full Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  subject: Yup.string().required("Subject is required"),
  message: Yup.string().required("Message is required"),
});

function ContactUs() {
  const formRef = useRef();

  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      subject: "",
      message: "",
    },
    validationSchema,
    onSubmit: () => {
      emailjs
        .sendForm(
          "service_ciopylm",
          "template_y68frl6",
          formRef.current,
          "2cs5xPlXQtad3byxq"
        )
        .then(
          () => {
            // console.log("Success...");
            toast.success("Message sent successfully!", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            formik.setValues({
              fullName: "",
              email: "",
              subject: "",
              message: "",
            });

            formik.setTouched({});
            formik.setErrors({});
          },
          (error) => {
            // console.log("FAILED...", error);
            toast.error(error, " Please try again.", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        );
    },
  });
  return (
    <section className="contactUsMain">
      <Container fluid="lg">
        <div>
          <article>
            <div>
              <aside>
                <img src={cube} alt="cube-icon" />
              </aside>
              <p>
                Let’s build something<p> great together.</p>
              </p>
              <aside>
                <img src={cone} alt="cone-icon" />
              </aside>
            </div>
            <div>
              <h3>Send Us Message</h3>
              <Form onSubmit={formik.handleSubmit} ref={formRef}>
                <span>
                  <Form.Control
                    type="text"
                    placeholder="Full Name"
                    name="fullName"
                    value={formik.values.fullName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isInvalid={
                      formik.touched.fullName && formik.errors.fullName
                    }
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    className="error-message"
                  >
                    {formik.errors.fullName}
                  </Form.Control.Feedback>
                </span>
                <span>
                  <Form.Control
                    type="text"
                    placeholder="Email Address"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isInvalid={formik.touched.email && formik.errors.email}
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    className="error-message"
                  >
                    {formik.errors.email}
                  </Form.Control.Feedback>
                </span>
                <span>
                  <Form.Control
                    type="text"
                    placeholder="Subject"
                    name="subject"
                    value={formik.values.subject}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isInvalid={formik.touched.subject && formik.errors.subject}
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    className="error-message"
                  >
                    {formik.errors.subject}
                  </Form.Control.Feedback>
                </span>
                <span>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Message"
                    name="message"
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isInvalid={formik.touched.message && formik.errors.message}
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    className="error-message"
                  >
                    {formik.errors.message}
                  </Form.Control.Feedback>
                </span>
                <span>
                  <button type="submit">Send Us Message</button>
                </span>
              </Form>
            </div>
          </article>
        </div>
      </Container>
    </section>
  );
}

export default ContactUs;
