import areaGasAlarm from "src/assets/images/projects/areagas/areagas.png";
import otPanel from "src/assets/images/projects/otpanel/otpanel.png";
import herzmann from "src/assets/images/projects/herzmann/herzmann.png";
import hisaabdaar from "src/assets/images/projects/hisaabdaar/hisaabdaar.png";
import emiportal from "src/assets/images/projects/emiportal/emiportal.png";
import jadafarmer from "src/assets/images/projects/jadafarmer/jadafarmer.png";

export const projectsFirstLine = "SOME OF OUR";
export const projectsTitle = "Finest Projects";

export const projectsArray = [
  {
    slideNo: "01",
    slideTitle: "Area Gas Alarm",
    slideDescription:
      "A medical area gas alarm is a specialized safety device to monitor the level of various gases and ensure the safety of patients, staff, and visitors.",
    linkText: "Discover the project",
    path: "/projects#area-gas-alarm",
    projectImage: areaGasAlarm,
  },
  {
    slideNo: "02",
    slideTitle: "OT Panel",
    slideDescription:
      "The OT Control Panel is a powerful and indispensable tool for the hospital's operations theater, offering features like VOIP (voice over IP), efficient control over surgical lights, OT lights, temperature, humidity, airflow, pressure, gas monitoring, and other essential features.",
    linkText: "Discover the project",
    path: "/projects#ot-control-panel",
    projectImage: otPanel,
  },
  {
    slideNo: "03",
    slideTitle: "Herzmann",
    slideDescription:
      "Herzmann is a designer, developer and manufacturer of modular operating theaters and laboratory furniture.",
    linkText: "Discover the project",
    path: "/projects#herzmann",
    projectImage: herzmann,
  },
  {
    slideNo: "04",
    slideTitle: "HisaabDaar",
    slideDescription:
      "Hisabdaar is fastest growing & amazingly simple to use online accounting & Inventory software.",
    linkText: "Discover the project",
    path: "/projects#hisaabdaar",
    projectImage: hisaabdaar,
  },
  {
    slideNo: "05",
    slideTitle: "EMI Portal",
    slideDescription:
      "The EMI Portal was designed to oversee comprehensive company data, encompassing employee details like attendance and project involvement, as well as client information along with project specifics.",
    linkText: "Discover the project",
    path: "/projects#emi-portal",
    projectImage: emiportal,
  },
  {
    slideNo: "06",
    slideTitle: "Jada Farmer",
    slideDescription:
      "In the realm of efficient business operations, an integrated suite of tools has emerged to redefine management practices.",
    linkText: "Discover the project",
    path: "/projects#jada-farmer",
    projectImage: jadafarmer,
  },
];
