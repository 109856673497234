import React, { useEffect } from "react";
import AboutUsBanner from "./banner";
import AboutUsSection from "./aboutus";
import OurMission from "./ourmission";
import ProcessDev from "./process";

const AboutUsPage = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <AboutUsBanner />
      <OurMission />
      <ProcessDev />
      <AboutUsSection />
    </>
  );
};

export default AboutUsPage;
