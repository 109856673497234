import React, { useEffect } from "react";
import Banner from "./banner";
import Herzmann from "./herzmann";
import Emiportal from "./emiportal";
import Hisaabdaar from "./hisaabdaar";
import Jadafarmer from "./jadafarmer";
import Otpanel from "./otpanel";
import Areagas from "./areagas";

const ProjectsPage = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    const href = window.location.href.substring(
      window.location.href.lastIndexOf("#") + 1
    );
    const element = document.getElementById(href);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, []);
  return (
    <>
      <Banner />

      <Herzmann />
      <Hisaabdaar />
      <Emiportal />
      <Jadafarmer />
      <Areagas />
      <Otpanel />
    </>
  );
};

export default ProjectsPage;
