import hisaabdaarImage from "src/assets/images/projects/hisaabdaar/hisaabdaar.png";
import reactLogo from "src/assets/images/projects/react.png";
import nodeLogo from "src/assets/images/projects/node.png";

export const hisaabdaarProjectData = {
  projectTitle: "HisaabDaar",
  description:
    "Hisabdaar is fastest growing & amazingly simple to use online accounting & Inventory software.",
  image: hisaabdaarImage,
  technologies: [
    {
      icon: reactLogo,
      title: "React",
    },
    {
      icon: nodeLogo,
      title: "NodeJS",
    },
  ],
};
