import React from "react";
import { Carousel, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BsArrowRightCircle } from "react-icons/bs";
import * as data from "./projectsData";
import "./projects.scss";

const HomeProjects = () => {
  return (
    <section className="homeProjectsMain">
      <Container fluid="lg">
        <div>
          <span>{data.projectsFirstLine}</span>
          <h1>{data.projectsTitle}</h1>

          <article>
            <Carousel controls={false} slide={true}>
              {data.projectsArray.map((state, idx) => {
                return (
                  <Carousel.Item key={idx} interval={5000}>
                    <div>
                      <div>
                        <p>
                          <span>{state.slideNo}</span>/
                          <span>{data.projectsArray.length}</span>
                        </p>
                        <h3>{state.slideTitle}</h3>
                        <p>{state.slideDescription}</p>
                        <Link to={`${state.path}`}>
                          {state.linkText}
                          <span>
                            <BsArrowRightCircle />
                          </span>
                        </Link>
                      </div>
                      <div>
                        <span>
                          <img
                            src={state.projectImage}
                            alt={state.slideTitle}
                          />
                        </span>
                      </div>
                    </div>
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </article>
        </div>
      </Container>
    </section>
  );
};

export default HomeProjects;
