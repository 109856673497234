import emiportalImage from "src/assets/images/projects/emiportal/emiportal.png";
import reactLogo from "src/assets/images/projects/react.png";
import nodeLogo from "src/assets/images/projects/node.png";

export const emiportalProjectData = {
  projectTitle: "EMI Portal",
  description:
    "EMI Portal was created to monitor all the data related to the company which includes employees information such as attendance and projects on which they have worked or still working, clients information with their project details.",
  image: emiportalImage,
  technologies: [
    {
      icon: reactLogo,
      title: "React",
    },
    {
      icon: nodeLogo,
      title: "NodeJS",
    },
  ],
};
