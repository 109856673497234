import React, { useEffect } from "react";
import ContactUs from "./contact";

const ContactUsPage = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <ContactUs />
    </>
  );
};

export default ContactUsPage;
