import herzmannImage from "src/assets/images/projects/herzmann/herzmann.png";
import reactLogo from "src/assets/images/projects/react.png";
import nodeLogo from "src/assets/images/projects/node.png";
import mongoLogo from "src/assets/images/projects/mongo.png";

export const herzmannProjectData = {
  projectTitle: "Herzmann",
  description:
    "Herzmann is a designer, developer and manufacturer of modular operating theaters and laboratory furniture.",
  image: herzmannImage,
  technologies: [
    {
      icon: reactLogo,
      title: "React",
    },
    {
      icon: nodeLogo,
      title: "NodeJS",
    },
    {
      icon: mongoLogo,
      title: "Mongo DB",
    },
  ],
};
