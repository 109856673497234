import React from "react";
import RightImageLeftText from "src/components/custom/Projects/rightImageLeftText";
import { emiportalProjectData } from "./emiportal";

const Emiportal = () => {
  return (
    <RightImageLeftText
      projectTitle={emiportalProjectData.projectTitle}
      projectDescription={emiportalProjectData.description}
      projectImage={emiportalProjectData.image}
      technologiesArray={emiportalProjectData.technologies}
    />
  );
};

export default Emiportal;
