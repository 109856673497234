export const aboutUsData = {
  aboutUsTitle: "Why should you work with us?",
  aboutUsDescription:
    "You'll find a team that aims to build great apps and will gladly cooperate with you from idea conception to the final release.",
  ourAims: [
    {
      id: 1,
      bgColor: "#E15662",
      description:
        "By increasing employee engagement commitment through specific",
    },
    {
      id: 2,
      bgColor: "#56C0E1",
      description: "Take ownership and question the status quo in a useful way",
    },
    {
      id: 3,
      bgColor: "#56E164",
      description:
        "Be brave, curious and experiment, learn from all successes and disappointments",
    },
    {
      id: 4,
      bgColor: "#F4DB80",
      description: "Act in a way that makes all of us proud",
    },
    {
      id: 5,
      bgColor: "#CE56E1",
      description: "Exceed clients’ and colleagues’ expectations",
    },
    {
      id: 6,
      bgColor: "#C5E156",
      description:
        "Fabricate a comprehensive, straightforward and socially dependable culture",
    },
  ],
};
