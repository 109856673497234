import React from "react";
// import { Link } from "react-router-dom";
// import { AiOutlineArrowRight } from "react-icons/ai";

const ServiceImageSection = ({ image, description, path, altText }) => {
  return (
    <div className="activeService">
      <span>
        <img src={image} alt={`${altText}`} />
      </span>
      <p>{description}</p>
      {/* <Link to={`${path}`}>
        Read More
        <span>
          -<AiOutlineArrowRight />
        </span>
      </Link> */}
    </div>
  );
};

export default ServiceImageSection;
