import areagasImage from "src/assets/images/projects/areagas/areagas.png";
import nodeLogo from "src/assets/images/projects/node.png";
import awsLogo from "src/assets/images/projects/aws.png";
import cLogo from "src/assets/images/projects/c.png";

export const areagasProjectData = {
  projectTitle: "Area Gas Alarm",
  description:
    "A medical area gas alarm is a specialized safety device to monitor the level of various gases and ensure the safety of patients, staff, and visitors.",
  image: areagasImage,
  technologies: [
    {
      icon: nodeLogo,
      title: "NodeJS",
    },
    {
      icon: cLogo,
      title: "C",
    },
    {
      icon: awsLogo,
      title: "AWS",
    },
  ],
};
