import client1 from "src/assets/images/home/clients/herzmann.png";
import precitech from "src/assets/images/home/clients/precitech.png";

export const clientsLogoArray = [
  client1,
  precitech,
  client1,
  precitech,
  client1,
  precitech,
  client1,
  precitech,
  client1,
  precitech,
  client1,
  precitech,
];

export const sliderSettings = {
  dots: false,
  speed: 500,
  arrows: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 3000,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 550,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 1150,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1550,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 2150,
      settings: {
        slidesToShow: 5,
      },
    },
    {
      breakpoint: 3050,
      settings: {
        slidesToShow: 6,
      },
    },
  ],
};
