import React from "react";
import { jadafarmerProjectData } from "./jadafarmer";
import LeftImageRightText from "src/components/custom/Projects/leftImageRightText";

const Jadafarmer = () => {
  return (
    <LeftImageRightText
      projectTitle={jadafarmerProjectData.projectTitle}
      projectDescription={jadafarmerProjectData.description}
      projectImage={jadafarmerProjectData.image}
      technologiesArray={jadafarmerProjectData.technologies}
    />
  );
};

export default Jadafarmer;
