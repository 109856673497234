export const listArray = ["UI/UX", "Embedded", "IT"];

export const homeBannerTitle = "Transform Your Idea Into Product.";

export const propertiesArr = [
  {
    value: "5K+",
    title: "Lives Touch",
  },
  {
    value: "10+",
    title: "Projects",
  },
  {
    value: "20+",
    title: "Tech Experts",
  },
];
