import otpanelImage from "src/assets/images/projects/otpanel/otpanel.png";
import reactLogo from "src/assets/images/projects/react.png";
import nodeLogo from "src/assets/images/projects/node.png";
import awsLogo from "src/assets/images/projects/aws.png";
import qtLogo from "src/assets/images/projects/qt.png";

export const otpanelProjectData = {
  projectTitle: "OT Control Panel",
  description:
    "The OT Control Panel is a powerful and indispensable tool for the hospital's operations theater, offering features like VOIP (voice over IP), efficient control over surgical lights and other essential features.",
  image: otpanelImage,
  technologies: [
    {
      icon: reactLogo,
      title: "React",
    },

    {
      icon: qtLogo,
      title: "QT",
    },
    {
      icon: awsLogo,
      title: "AWS",
    },
    {
      icon: nodeLogo,
      title: "NodeJS",
    },
  ],
};
