import React from "react";
import { Container } from "react-bootstrap";
import { aboutUsData } from "./aboutusdata";
import "./AboutUsSection.scss";

function AboutUsSection() {
  return (
    <section className="aboutUsSectionMain">
      <Container fluid="lg">
        <div className="d-flex flex-column align-items-center">
          <h3>{aboutUsData.aboutUsTitle}</h3>
          <p>{aboutUsData.aboutUsDescription}</p>
          <article>
            {aboutUsData.ourAims.map((state, idx) => {
              return (
                <span key={idx}>
                  <p
                    className="d-flex align-items-center"
                    style={{ backgroundColor: state.bgColor }}
                  >
                    {state.id}
                  </p>
                  <p>{state.description}</p>
                </span>
              );
            })}
          </article>
        </div>
      </Container>
    </section>
  );
}

export default AboutUsSection;
