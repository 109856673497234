export const navLinksArray = [
  {
    linkName: "Home",
    path: "/",
  },
  {
    linkName: "About",
    path: "/about",
  },
  {
    linkName: "Services",
    path: "/#services",
  },
  {
    linkName: "Projects",
    path: "/projects",
  },
];
