import React from "react";
import RightImageLeftText from "src/components/custom/Projects/rightImageLeftText";
import { herzmannProjectData } from "./herzmanndata";

const Herzmann = () => {
  return (
    <RightImageLeftText
      projectTitle={herzmannProjectData.projectTitle}
      projectDescription={herzmannProjectData.description}
      projectImage={herzmannProjectData.image}
      technologiesArray={herzmannProjectData.technologies}
    />
  );
};

export default Herzmann;
