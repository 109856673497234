import React from "react";
import { Route, Routes } from "react-router-dom";
import AppTemplateLayout from "src/components/layout/AppTempleteLayout";
import HomePage from "./routes/home";
import "bootstrap/dist/css/bootstrap.min.css";
import ProjectsPage from "./routes/projects";
import AboutUsPage from "./routes/about";
import ContactUsPage from "./routes/contact-us";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<AppTemplateLayout />}>
          <Route index element={<HomePage />} />
          <Route path="projects" element={<ProjectsPage />} />
          <Route path="about" element={<AboutUsPage />} />
          <Route path="contact-us" element={<ContactUsPage />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
