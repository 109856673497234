import React from "react";
import LeftImageRightText from "src/components/custom/Projects/leftImageRightText";
import { hisaabdaarProjectData } from "./hisaabdaar";

const Hisaabdaar = () => {
  return (
    <LeftImageRightText
      projectTitle={hisaabdaarProjectData.projectTitle}
      projectDescription={hisaabdaarProjectData.description}
      projectImage={hisaabdaarProjectData.image}
      technologiesArray={hisaabdaarProjectData.technologies}
    />
  );
};

export default Hisaabdaar;
