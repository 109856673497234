import React, { useState } from "react";
import { Container } from "react-bootstrap";
import * as data from "./ourServicesData";
import ServiceImageSection from "./ServiceImageSection";
import "./ourServices.scss";

const OurServices = () => {
  const [activeButtonIndex, setActiveButtonIndex] = useState(0);
  return (
    <section className="ourServicesMain" id="services">
      <Container fluid="lg">
        <div>
          <span>{data.expertise}</span>
          <h1>{data.ourServicesTitle}</h1>
          <p>{data.ourServicesDescription}</p>
          <article>
            <div>
              {data.ourServiceArray.map((state, idx) => {
                return (
                  <button
                    key={idx}
                    className={idx === activeButtonIndex ? "activeButton" : ""}
                    onClick={() => setActiveButtonIndex(idx)}
                  >
                    <span>{state.title}</span>
                    <p>{state.description}</p>
                  </button>
                );
              })}
            </div>
            <div>
              {activeButtonIndex === 0 && (
                <ServiceImageSection
                  image={data.ourServiceImagesArray[0].image}
                  altText={data.ourServiceImagesArray[0].alt}
                  description={data.ourServiceImagesArray[0].description}
                  path={data.ourServiceImagesArray[0].path}
                />
              )}
              {activeButtonIndex === 1 && (
                <ServiceImageSection
                  image={data.ourServiceImagesArray[1].image}
                  altText={data.ourServiceImagesArray[1].alt}
                  description={data.ourServiceImagesArray[1].description}
                  path={data.ourServiceImagesArray[1].path}
                />
              )}
              {activeButtonIndex === 2 && (
                <ServiceImageSection
                  image={data.ourServiceImagesArray[2].image}
                  altText={data.ourServiceImagesArray[2].alt}
                  description={data.ourServiceImagesArray[2].description}
                  path={data.ourServiceImagesArray[2].path}
                />
              )}
              {activeButtonIndex === 3 && (
                <ServiceImageSection
                  image={data.ourServiceImagesArray[3].image}
                  altText={data.ourServiceImagesArray[3].alt}
                  description={data.ourServiceImagesArray[3].description}
                  path={data.ourServiceImagesArray[3].path}
                />
              )}
            </div>
          </article>
        </div>
      </Container>
    </section>
  );
};

export default OurServices;
