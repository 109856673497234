import React from "react";
import { Container, Carousel } from "react-bootstrap";
import * as data from "./technologiesData";
import "./technologies.scss";

const HomeTechnologies = () => {
  return (
    <section className="technologiesMain">
      <Container fluid="lg">
        <div>
          <span>{data.technologiesFirstLine}</span>
          <h1>{data.technologiesTitle}</h1>
          <p>{data.technologiesDescription}</p>

          <article>
            <Carousel slide={true} controls={false}>
              <Carousel.Item interval={5000}>
                <div>
                  {data.technologiesArray.slice(0, 10).map((state, idx) => {
                    return (
                      <div className="box" key={idx}>
                        <span>
                          <img src={state.icon} alt={state.altText} />
                        </span>
                        <p className="text">{state.techTitle}</p>
                      </div>
                    );
                  })}
                </div>
              </Carousel.Item>
              <Carousel.Item interval={5000}>
                <div>
                  {data.technologiesArray.slice(10, 20).map((state, idx) => {
                    return (
                      <div className="box" key={idx}>
                        <span>
                          <img src={state.icon} alt={state.altText} />
                        </span>
                        <p className="text">{state.techTitle}</p>
                      </div>
                    );
                  })}
                </div>
              </Carousel.Item>
            </Carousel>
          </article>
        </div>
      </Container>
    </section>
  );
};

export default HomeTechnologies;
