import jadafarmerImage from "src/assets/images/projects/jadafarmer/jadafarmer.png";
import reactLogo from "src/assets/images/projects/react.png";

export const jadafarmerProjectData = {
  projectTitle: "Jada Farmer",
  description:
    "In the realm of efficient business operations, an integrated suite of tools has emerged to redefine management practices.",
  image: jadafarmerImage,
  technologies: [
    {
      icon: reactLogo,
      title: "React",
    },
    {
      icon: reactLogo,
      title: "React Native",
    },
  ],
};
