import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "../template/header";
import Footer from "../template/footer";

export default function AppTemplateLayout() {
  const { hash } = useLocation();

  const scrollToElement = () => {
    const element = document.getElementById(hash.slice(1));
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  useEffect(() => {
    if (hash) {
      scrollToElement();
    }
  }, [hash]);

  return (
    <>
      <Header />
      <main>
        <Outlet />
      </main>
      <Footer />
    </>
  );
}
