import React, { useRef } from "react";
import footerLogo from "src/assets/images/LogoWhite.png";
import { Container, Nav, Form } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import * as data from "./footerDataHome";
import * as footerData from "./footerData";
import "./footer.scss";
import { BiSolidPhone } from "react-icons/bi";
import { MdLocationOn } from "react-icons/md";
import { GrMail } from "react-icons/gr";
import twitterIcon from "../../../assets/images/footer/twitter-icon.png";
import facebookIcon from "../../../assets/images/footer/facebook-icon.png";
import linkedinIcon from "../../../assets/images/footer/linkedin-icon.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";

const validationSchema = Yup.object().shape({
  subEmail: Yup.string()
    .email("*Invalid email*")
    .required("*Email is required*"),
});

let date = new Date();
let year = date.getFullYear();

const Footer = () => {
  const formRef = useRef();

  const handleSocialClick = (url) => {
    window.location.href = url;
  };

  const handleClickScroll = () => {
    const element = document.getElementById("services");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleMailto = () => {
    const emailAddress = "Info@ternotechnologies.co.uk";
    window.location.href = `mailto:${emailAddress}`;
  };

  const socialIcons = [
    { icon: facebookIcon, url: "https://www.facebook.com" },
    { icon: twitterIcon, url: "https://www.twitter.com" },
    {
      icon: linkedinIcon,
      url: "https://www.linkedin.com/company/ternotechnologiesltd/",
    },
  ];

  const formik = useFormik({
    initialValues: {
      subEmail: "",
    },
    validationSchema,
    onSubmit: () => {
      emailjs
        .sendForm(
          "service_ciopylm",
          "template_xdh51re",
          formRef.current,
          "2cs5xPlXQtad3byxq"
        )
        .then(
          () => {
            // console.log("Success...");
            toast.success("Thank You for Subscribing!", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            formik.setValues({
              subEmail: "",
            });

            formik.setTouched({});
            formik.setErrors({});
          },
          (error) => {
            toast.error(error, " Please try again.", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        );
    },
  });

  return (
    <footer className="footerMain">
      <Container fluid="lg">
        <div className="footerWrapper">
          <div className="footerLogo">
            <img src={footerLogo} alt="tt-logo" />
            <span>
              Terno Technologies is your reliable partner to pioneer innovative
              industrial solutions, consumer electronics and IoT devices.
            </span>
          </div>
          <div className="quickLinks">
            <span>Quick Links</span>
            <Nav className="flex-column">
              {data.navLinksArray.map((state, idx) =>
                state.linkName !== "Services" ? (
                  <NavLink exact="true" key={idx} end to={`${state.path}`}>
                    {state.linkName}
                  </NavLink>
                ) : (
                  <Link
                    key={idx}
                    to={`${state.path}`}
                    onClick={handleClickScroll}
                  >
                    {state.linkName}
                  </Link>
                )
              )}
            </Nav>
          </div>
          <div className="aboutNav">
            <span>About</span>
            <Nav className="flex-column">
              {footerData.footerNavLinksArray.map((state, idx) => {
                return (
                  <Link exact="true" key={idx} end to={`${state.path}`}>
                    {state.linkName}
                  </Link>
                );
              })}
            </Nav>
          </div>
          <div className="addressNav">
            <span className="addressHeading">Address</span>
            <span className="addressIcon">
              <MdLocationOn />
              <span>Leeds, United Kingdom</span>
            </span>
            <span className="contactHeading">Contact Number</span>
            <span className="contactIcon">
              <BiSolidPhone />
              <span>+44 7936600092</span>
            </span>
            <span className="mailtHeading">Email</span>
            <span className="emailIcon">
              <GrMail />
              <span onClick={handleMailto}>Info@ternotechnologies.co.uk</span>
            </span>
            <span className="subscribeHeading">Subscribe</span>

            <span className="subscribeEmail">
              <Form
                onSubmit={formik.handleSubmit}
                ref={formRef}
                className="subForm"
              >
                <Form.Control
                  type="email"
                  placeholder="Email Address"
                  className="joinEmail"
                  name="subEmail"
                  value={formik.values.subEmail}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.subEmail && formik.errors.subEmail}
                />

                <button type="submit" className="joinButton">
                  Join
                </button>
              </Form>
            </span>
            <span>
              <Form.Control.Feedback type="invalid" className="error-message">
                {formik.errors.subEmail}
              </Form.Control.Feedback>
            </span>
          </div>
        </div>
        <div className="socialLinks">
          {socialIcons.map((iconData, index) => (
            <span key={index}>
              <img
                src={iconData.icon}
                alt={`${index}`}
                onClick={() => handleSocialClick(iconData.url)}
                style={{ cursor: "pointer" }}
              />
            </span>
          ))}
        </div>
        <div className="license">
          <span>
            Copyright &copy; 2023-{year} Terno Technologies. All rights
            reserved. Version 1.0.0.
          </span>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
