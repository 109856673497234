import React from "react";
import LeftImageRightText from "src/components/custom/Projects/leftImageRightText";
import { otpanelProjectData } from "./otpanel";

const Otpanel = () => {
  return (
    <LeftImageRightText
      projectTitle={otpanelProjectData.projectTitle}
      projectDescription={otpanelProjectData.description}
      projectImage={otpanelProjectData.image}
      technologiesArray={otpanelProjectData.technologies}
    />
  );
};

export default Otpanel;
