import React from "react";
import Slider from "react-slick";
import { Container } from "react-bootstrap";
import * as data from "./clientsData";
import "./clients.scss";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HomeClients = () => {
  return (
    <section className="clientsMain">
      <Container fluid="lg">
        <Slider {...data.sliderSettings}>
          {data.clientsLogoArray.map((state, idx) => {
            return (
              <span key={idx}>
                <img src={state} alt="client-logo" />
              </span>
            );
          })}
        </Slider>
      </Container>
    </section>
  );
};

export default HomeClients;
