import React from "react";
import RightImageLeftText from "src/components/custom/Projects/rightImageLeftText";
import { areagasProjectData } from "./areagas";

const Areagas = () => {
  return (
    <RightImageLeftText
      projectTitle={areagasProjectData.projectTitle}
      projectDescription={areagasProjectData.description}
      projectImage={areagasProjectData.image}
      technologiesArray={areagasProjectData.technologies}
    />
  );
};

export default Areagas;
