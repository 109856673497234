import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import imageOurMission from "src/assets/images/about/ourmissionimage.png";
import "./ourmission.scss";

function OurMission() {
  return (
    <section className="ourMissionMain">
      <Container fluid="lg">
        <div>
          <article>
            <span>
              <img src={imageOurMission} alt="Our Mission" />
            </span>
            <div>
              <h3>Transforming Ideas Into Scalable Products</h3>
              <p>
                From high tech industry to consumer market, our clients come to
                us with inspiring ideas and most challenging real life problems.
                We have a team of professional engineers ready to deliver
                end-to-end product development solutions.
              </p>
              <Link to="/contact-us">Contact Us</Link>
            </div>
          </article>
        </div>
      </Container>
    </section>
  );
}

export default OurMission;
