import react from "src/assets/images/home/texhnologies/react.png";
import html5 from "src/assets/images/home/texhnologies/html.png";
import css from "src/assets/images/home/texhnologies/css.png";
import javascript from "src/assets/images/home/texhnologies/javascript.png";
import node from "src/assets/images/home/texhnologies/node.png";
import mongo from "src/assets/images/home/texhnologies/mongo.png";
import python from "src/assets/images/home/texhnologies/python.png";
import java from "src/assets/images/home/texhnologies/java.png";
import android from "src/assets/images/home/texhnologies/android.png";
import angular from "src/assets/images/home/texhnologies/angular.png";
import c_lang from "src/assets/images/home/texhnologies/clang.png";
import c_hash from "src/assets/images/home/texhnologies/chash.png";
import c_plusplus from "src/assets/images/home/texhnologies/cplusplus.png";
import apple from "src/assets/images/home/texhnologies/apple.png";
import resberrypi from "src/assets/images/home/texhnologies/resberrypi.png";
import mysql from "src/assets/images/home/texhnologies/mysql.png";
import linux from "src/assets/images/home/texhnologies/linux.png";
import figma from "src/assets/images/home/texhnologies/figma.png";
import next from "src/assets/images/home/texhnologies/nextjs.png";
import qt from "src/assets/images/home/texhnologies/qt.png";

export const technologiesFirstLine = "WE WORK ON THESE";
export const technologiesTitle = "Technologies";
export const technologiesDescription =
  "With our business model, you have options to choose the solution that best fits your need.";

export const technologiesArray = [
  {
    icon: react,
    altText: "react-logo",
    techTitle: "React",
  },
  {
    icon: html5,
    altText: "html-logo",
    techTitle: "HTML5",
  },
  {
    icon: css,
    altText: "css-logo",
    techTitle: "CSS",
  },
  {
    icon: javascript,
    altText: "javascript-logo",
    techTitle: "JavaScript",
  },
  {
    icon: node,
    altText: "node-logo",
    techTitle: "NodeJS",
  },
  {
    icon: mongo,
    altText: "mongoDB-logo",
    techTitle: "MongoDB",
  },
  {
    icon: python,
    altText: "python-logo",
    techTitle: "Pyhton",
  },
  {
    icon: java,
    altText: "java-logo",
    techTitle: "Java",
  },
  {
    icon: android,
    altText: "android-logo",
    techTitle: "Android",
  },
  {
    icon: angular,
    altText: "angular-logo",
    techTitle: "Angular",
  },
  {
    icon: c_lang,
    altText: "c-logo",
    techTitle: "C",
  },
  {
    icon: c_hash,
    altText: "C#-logo",
    techTitle: "C#",
  },
  {
    icon: c_plusplus,
    altText: "C++-logo",
    techTitle: "C++",
  },
  {
    icon: apple,
    altText: "ios-logo",
    techTitle: "IOS",
  },
  {
    icon: resberrypi,
    altText: "rasberrypi-logo",
    techTitle: "Rasberrypi",
  },
  {
    icon: mysql,
    altText: "mysql-logo",
    techTitle: "MySQL",
  },
  {
    icon: linux,
    altText: "linux-logo",
    techTitle: "Linux",
  },
  {
    icon: figma,
    altText: "figma-logo",
    techTitle: "Figma",
  },
  {
    icon: next,
    altText: "nextjs-logo",
    techTitle: "Next.js",
  },
  {
    icon: qt,
    altText: "qt-logo",
    techTitle: "Qt",
  },
];
