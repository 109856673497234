import React, { useEffect } from "react";
import HomeBanner from "./banner";
import HomeClients from "./clients";
import OurServices from "./ourServices";
import HomeTechnologies from "./technologies";
import HomeProjects from "./projects";

const HomePage = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    const href = window.location.href.substring(
      window.location.href.lastIndexOf("#") + 1
    );
    const element = document.getElementById(href);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, []);

  return (
    <>
      <HomeBanner />

      <HomeClients />

      <OurServices />

      <HomeTechnologies />

      <HomeProjects />
    </>
  );
};

export default HomePage;
