import React from "react";
import { Container } from "react-bootstrap";
import { processData } from "./process";
import "./process.scss";

function ProcessDev() {
  return (
    <section className="processSectionMain">
      <Container fluid="lg">
        <div>
          <h3>{processData.processTitle}</h3>
          <p>{processData.processDescription}</p>
          <article>
            {processData.ourProcessArray.map((state, idx) => {
              return (
                <div key={idx}>
                  <span>
                    <img src={state.icon} alt={state.description} />
                  </span>
                  <p>{state.description}</p>
                </div>
              );
            })}
          </article>
        </div>
      </Container>
    </section>
  );
}

export default ProcessDev;
