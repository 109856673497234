import React from "react";
import { Container } from "react-bootstrap";
import "./aboutBanner.scss";

const AboutUsBanner = () => {
  return (
    <section className="aboutBannerMain">
      <div className="overlay"></div>
      <Container fluid="lg">
        <div>
          <h1>About Us</h1>
          <p>
            Terno Technologies is your reliable partner to pioneer innovative
            industrial solutions, consumer electronics and IoT devices.
          </p>
        </div>
      </Container>
    </section>
  );
};

export default AboutUsBanner;
