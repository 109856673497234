import React, { useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import navLogo from "src/assets/images/Logo.png";
import * as data from "./headerData";
import "./header.scss";

const Header = () => {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  const closeNavbar = () => {
    setIsNavbarOpen(false);
  };

  return (
    <header className="headerMain">
      <Navbar
        expand="md"
        expanded={isNavbarOpen}
        onToggle={() => setIsNavbarOpen(!isNavbarOpen)}
      >
        <Container fluid="lg">
          <Link to="/">
            <img src={navLogo} alt="emi-logo" />
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse
            id="basic-navbar-nav"
            className={isNavbarOpen ? "show" : ""}
          >
            <Nav className="ms-auto gap-md-4 gap-3">
              {data.navLinksArray.map((state, idx) =>
                state.linkName !== "Services" ? (
                  <NavLink
                    exact
                    key={idx}
                    to={`${state.path}`}
                    className={({ isActive }) =>
                      isActive && state.linkName !== "Contact us"
                        ? "Active"
                        : " "
                    }
                    onClick={closeNavbar}
                  >
                    {state.linkName}
                  </NavLink>
                ) : (
                  <Link
                    key={idx}
                    to={`${state.path}`}
                    onClick={() => {
                      closeNavbar();
                      setTimeout(() => {
                        document.getElementById("services").scrollIntoView({
                          behavior: "smooth",
                        });
                      }, 300);
                    }}
                  >
                    {state.linkName}
                  </Link>
                )
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
