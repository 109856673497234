import conceptDevelopment from "src/assets/images/about/process/conceptdevelopment.png";
import detailedEngineering from "src/assets/images/about/process/detailedengineering.png";
import prototypeAndTest from "src/assets/images/about/process/prototypeandtest.png";
import manufacturing from "src/assets/images/about/process/manufacturing.png";

export const processData = {
  processTitle: "Product Development Process",
  processDescription:
    "Our product development cycle has refined over the years to ensure high product quality, reduce development costs and shorten time to market.",
  ourProcessArray: [
    {
      icon: conceptDevelopment,
      description: "Concept Development",
    },
    {
      icon: detailedEngineering,
      description: "Detailed Engineering",
    },
    {
      icon: prototypeAndTest,
      description: "Prototype And Test",
    },
    {
      icon: manufacturing,
      description: "Manufacturing",
    },
  ],
};
