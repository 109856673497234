import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import * as data from "./bannerData";
import "./banner.scss";

const HomeBanner = () => {
  const [active, setActive] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActive((prevActive) => prevActive + 1);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (active > 2) {
      setActive(0);
    }
  }, [active]);

  return (
    <section className="homeBannerMain">
      <Container fluid="lg">
        <div className="d-flex flex-column justify-content-md-center align-items-start">
          <article>
            {data.listArray.map((state, idx) => {
              return (
                <p key={idx} className={idx === active ? "show" : "hide"}>
                  {state}
                </p>
              );
            })}

            <h1>{data.homeBannerTitle}</h1>

            <div>
              {data.propertiesArr.map((state, idx) => {
                return (
                  <p key={idx}>
                    <span>{state.value}</span>
                    <span>{state.title}</span>
                  </p>
                );
              })}
            </div>
          </article>
        </div>
      </Container>
    </section>
  );
};

export default HomeBanner;
