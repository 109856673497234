import React from "react";
import { Container } from "react-bootstrap";
import "./rightImageLeftText.scss";
import { kebabCaseConvertion } from "src/utils/functions/kebabCaseConversion";

const RightImageLeftText = (props) => {
  const { projectTitle, projectDescription, projectImage, technologiesArray } =
    props;
  return (
    <section
      className="rightImageLeftText"
      id={`${kebabCaseConvertion(projectTitle)}`}
    >
      <Container fluid="lg">
        <div>
          <article>
            <div>
              <h2>{projectTitle}</h2>
              <p>{projectDescription}</p>
              <div>
                {technologiesArray.map((state, idx) => {
                  return (
                    <span key={idx}>
                      <img src={state.icon} alt={state.title} />
                      <p>{state.title}</p>
                    </span>
                  );
                })}
              </div>
            </div>
            <aside>
              <img src={projectImage} alt={`${projectTitle} Project Snip`} />
            </aside>
          </article>
          <br />
        </div>
      </Container>
    </section>
  );
};

export default RightImageLeftText;
