export const footerNavLinksArray = [
  {
    linkName: "Contact",
    path: "/contact-us",
  },
  {
    linkName: "Term",
    path: "/term",
  },
];
