import userExperience from "src/assets/images/home/services/ui-ux-design.webp";
import productDesign from "src/assets/images/home/services/productDesign.webp";
import webDevelopment from "src/assets/images/home/services/webDevelopment.webp";
import embeddedSystem from "src/assets/images/home/services/embeddedSystem.webp";

export const expertise = "EXPERTISE";
export const ourServicesTitle = "Our Services";
export const ourServicesDescription =
  "From initial idea to market-ready product. we’ll guide you through the process and bring your vision to life.";

export const ourServiceArray = [
  {
    title: "User Experience Design",
    description: "Our UI/UX design fosters a sense of trust and...",
  },
  {
    title: "Website & App Development",
    description: "Our development team adheres to best practices in...",
  },
  {
    title: "Embedded System",
    description: "Our embedded systems are meticulously designed to provide...",
  },
  {
    title: "Product Design",
    description: "Sustainability and eco-friendliness are embedded in our...",
  },
];

export const ourServiceImagesArray = [
  {
    image: userExperience,
    alt: "user-experience-design",
    description:
      "Our UI/UX design fosters a sense of trust and reliability through consistent, user-friendly interactions, ultimately ensuring a memorable and delightful experience for our customers.",
    path: "/",
  },
  {
    image: webDevelopment,
    alt: "website-and-app-development",
    description:
      "Our development team adheres to best practices in security and scalability, ensuring the service can handle increasing demands and providing users with a safe environment.",
    path: "/",
  },
  {
    image: embeddedSystem,
    alt: "embedded system",
    description:
      "Our embedded systems are meticulously designed to provide a reliable foundation for our service, seamlessly integrating hardware and software components for optimal performance.",
    path: "/",
  },
  {
    image: productDesign,
    alt: "product-design",
    description:
      "Sustainability and eco-friendliness are embedded in our product design ethos, reflecting our commitment to responsible and ethical service delivery.",
    path: "/",
  },
];
