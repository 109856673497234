import React from "react";
import { Container } from "react-bootstrap";
import "./banner.scss";

const Banner = () => {
  return (
    <section className="projectsBannerMain">
      <Container fluid="lg">
        <div>
          <h1>Projects We’ve Delivered</h1>
          <p>
            Over the past 10 years, we have designed and built a wide range of
            high-quality products from scratch. Our team has finished various
            web and mobile apps related to various spheres including e-learning,
            healthcare, e-commerce, advertising, augmented reality, action
            sports, finance and sharing economy. See out Portfolio below.
          </p>
        </div>
      </Container>
    </section>
  );
};

export default Banner;
